<template>
  <div class="salepage">
    <div v-if="type && type === 1">
      <products-main-page
        :product="product"
        :productCoach="productCoach"
        :previewId="previewId"
        :welcomeImage="welcomeImage"
        :reviewImages="reviewImages"
        :createOrderUrl="createOrderUrl"
      ></products-main-page>
    </div>
    <div v-if="type && type === 2">
      <evoucher-main-page
        :product="product"
        :productCoach="productCoach"
        :previewId="previewId"
        :welcomeImage="welcomeImage"
        :reviewImages="reviewImages"
        :createOrderUrl="createOrderUrl"
      ></evoucher-main-page>
    </div>
    <thai-qr-pay-label class="salepage-thai-qr"></thai-qr-pay-label>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import moomallService from '../services/moomallServices'

export default Vue.extend({
  components: {},
  data() {
    return {
      product: null,
      productCoach: null,
      previewId: 1,
      welcomeImage: null,
      reviewImages: null,
      type: null,
      createOrderUrl: null,
    }
  },
  async created() {
    await this.getSalepage(this.$route.params.salepageName)
    if (this.$route.query.previewId) {
      this.previewId = this.$route.query.previewId
    }
  },
  methods: {
    async getSalepage(routeName) {
      try {
        const resp = await moomallService.get(
          `/product_coach/name/${routeName}`,
        )
        const data = resp.data

        const { product, productCoach, welcomeImage, reviewImages } = data

        this.type = productCoach.type
        this.product = product
        this.productCoach = productCoach
        this.welcomeImage = welcomeImage
        this.reviewImages = reviewImages
        this.createOrderUrl = `${routeName}/create_order`

        // NOTE: They want to count only normal case
        this.incrementCountSalepage(routeName)
      } catch (error) {
        console.log('err', error)
        this.$router.push({ path: '/error/404' })
      }
    },
    incrementCountSalepage(routeName) {
      try {
        moomallService.post(`/product_coach/name/${routeName}/count`)
      } catch (error) {
        console.error('Increment SP Error', error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/scss/salepage.scss';

.salepage {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;

  &-thai-qr {
    margin-bottom: 84.5px;
  }
}
</style>
